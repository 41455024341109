// import api from "api";
import API from "../../constants/ApiConstant";
import api from "api";
// const access_token = getAccessToken();

export function listNft(params) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.NFTS}`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function addNft(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(API.NFTS, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateCollection(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .put(`${API.NFTS}/${id}`, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function uploadNft(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`${API.NFTS}/upload`, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getTokenUri(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`${API.NFTS}/token-uri`, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateNft(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .put(`${API.NFTS}/${id}/minted`, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getNftById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.NFTS}/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function listCategories(params) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.CATEGORY}`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function hideNft(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .patch(`${API.NFTS}/admin/hide-nft/${id}`, payload)
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function featureNFT(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .patch(`${API.NFTS}/admin/feature-nft/${id}`, payload)
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getFeaturedNFTs(params) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.NFTS}/admin/featured`, { params })
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err.response.data);
      });
  });
}
