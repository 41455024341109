import api from "api";
import API from "../../constants/ApiConstant";

export function updateDetails({ id, payload }) {
  return new Promise((resolve, reject) => {
    api
      .put(`${API.USER_LOGIN}/${id}`, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
}

export function getById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.USER_LOGIN}/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
}

export function getMyDetails() {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.USER_LOGIN}/user/details`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
}

export function uploadImage(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`${API.USER_LOGIN}/uploadImage`, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
}

export function verifyAndSendOtp(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`${API.USER_LOGIN}/login`, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function verifyOtpAndLogin(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`${API.USER_LOGIN}/verify-login`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
