import api from "../../api";
import API from "constants/ApiConstant";
import { getAccessToken } from "utils/sessionManager";

export function getAppSettings() {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.SETTINGS}/list`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

// =====CMS Templating======
export function saveTemplateAndData(payload, docId) {
  if (docId) payload.docId = docId;
  return new Promise((resolve, reject) => {
    api
      .put(`${API.TEMPLATES}`, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getByPageName(name) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.TEMPLATES}/page/${name}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}
