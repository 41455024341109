import { createContext, useCallback, useReducer } from "react";
import appReduce from "./Reducer";
import * as Service from "./Service";
import Action from "./Action";

const initialState = {
  list: [],
  pagination: {
    limit: 10,
    start: 0,
    total: 0,
    currentPage: 1,
    totalPages: 0,
  },
};

export const GalleryContext = createContext(initialState);
export const GalleryContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReduce, initialState);

  const listGallery = useCallback(async (query) => {
    const res = await Service.listGallery(query);
    dispatch({ type: Action.LIST_GALLERY, data: res });
    return res;
  }, []);

  const hideGallery = useCallback(async (id, payload) => {
    const res = await Service.hideGallery(id, payload);
    dispatch({ type: Action.HIDE_GALLERY, data: res });
    return res;
  }, []);

  const featureGallery = useCallback(async (id, payload) => {
    const res = await Service.featureGallery(id, payload);
    dispatch({ type: Action.FEATURE_GALLERY, data: res });
    return res;
  }, []);

  const getFeaturedGalleries = useCallback(async (query) => {
    const res = await Service.getFeaturedGalleries(query);
    dispatch({ type: Action.GET_FEATURED_GALLERIES, data: res });
    return res;
  }, []);

  const getGalleryById = useCallback(async (id) => {
    const res = await Service.getGalleryById(id);
    dispatch({ type: Action.GET_GALLERY_BY_ID, data: res });
    return res;
  }, []);

  const updateGalleryInfo = useCallback(async(id,payload)=>{
    const res = await Service.editGalleryInfo(id,payload);
    return res;
},[])

  return (
    <GalleryContext.Provider
      value={{
        is_app_ready: state.is_app_ready,
        pagination: state.pagination,
        dispatch,
        listGallery,
        hideGallery,
        featureGallery,
        getFeaturedGalleries,
        getGalleryById,
        updateGalleryInfo
      }}>
      {children}
    </GalleryContext.Provider>
  );
};
