// import axios from "axios";
import api from "api";
import API from "../../constants/ApiConstant";
import qs from "query-string";

export function listArtist(query) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.ARTISTS}?${qs.stringify(query)}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function hideArtist(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .patch(`${API.ARTISTS}/admin/hide-artist/${id}`, payload)
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.ARTISTS}/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });
}

export function featureArtist(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .patch(`${API.ARTISTS}/admin/feature-artist/${id}`, payload)
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getFeaturedArtists(query) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.ARTISTS}/admin/artists/featured`, query)
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err.response.data);
      });
  });
}
