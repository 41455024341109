import React, { createContext, useReducer, useCallback } from "react";
import reducer from "./Reducer";
import APP_ACTIONS from "./Action";
import * as UserService from "../social/Service";
import * as Service from "./Service"

const initialState = {
  is_app_ready: false,
  userImage: null,
  current_membership: null,
  client_secret: null,
  checkout_success_url: null,
};

export const AppContext = createContext(initialState);
export const AppContextProvider = ({ children }) => {
  const [state,dispatch] = useReducer(reducer, initialState);

  const initApp = useCallback(async () => {
    dispatch({ type: APP_ACTIONS.INIT_APP, data: { is_app_ready: true } });
  }, [dispatch]);

  function getUserById(id) {
    return UserService.getById(id);
  }

  function saveUserImage(image) {
    dispatch({ type: APP_ACTIONS.SET_USER_IMAGE, data: { image } });
  }
  function saveTemplateAndData(payload, docId) {
		return Service.saveTemplateAndData(payload, docId);
	}

	function getByPageName(page_name) {
		return Service.getByPageName(page_name);
	}
  return (
    <AppContext.Provider
      value={{
        is_app_ready: state.is_app_ready,
				userImage: state.userImage,
				current_membership: state.current_membership,
				client_secret: state.client_secret,
				checkout_success_url: state.checkout_success_url,        getUserById,
        initApp,
        dispatch,
        saveUserImage,
        saveTemplateAndData,
        getByPageName
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
