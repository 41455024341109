import {
  FolderOpenOutlined,
  FileTextOutlined,
  LogoutOutlined,
  GiftOutlined,
  GlobalOutlined,
  HomeOutlined,
  PictureOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const origin = process.env.REACT_APP_WEBSITE;
const extraNavTree = [
  {
    key: "collection",
    path: `${APP_PREFIX_PATH}/collection/list`,
    title: "sidenav.collection",
    icon: FolderOpenOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "artists",
    path: `${APP_PREFIX_PATH}/artists/list`,
    title: "sidenav.artists",
    icon: TeamOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "nfts",
    path: `${APP_PREFIX_PATH}/nfts/list`,
    title: "sidenav.nft",
    icon: GiftOutlined,
    breadcrumb: false,
    submenu: [],
  },

  {
    key: "users",
    path: `${APP_PREFIX_PATH}/users/list`,
    title: "sidenav.users",
    icon: UsergroupAddOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "extra",
    path: `${APP_PREFIX_PATH}/pages`,
    title: "sidenav.pages",
    icon: PlusCircleOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "cms-home-page",
        path: `${APP_PREFIX_PATH}/cms/home-page`,
        title: "sidenav.pages.cms.home-page",
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "cms-youtube-page",
        path: `${APP_PREFIX_PATH}/cms/youtube-page`,
        title: "sidenav.pages.cms.youtube-page",
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "cms-privacy",
        path: `${APP_PREFIX_PATH}/cms/privacy`,
        title: "sidenav.pages.cms.privacy",
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "cms-terms",
        path: `${APP_PREFIX_PATH}/cms/terms`,
        title: "sidenav.pages.cms.terms",
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "cms-why-artswap",
        path: `${APP_PREFIX_PATH}/cms/why-artswap`,
        title: "sidenav.pages.cms.why-artswap",
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "cms-pricing",
        path: `${APP_PREFIX_PATH}/cms/pricing`,
        title: "sidenav.pages.cms.pricing",
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "cms-about-us",
        path: `${APP_PREFIX_PATH}/cms/about-us`,
        title: "sidenav.pages.cms.about",
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "Website",
    externalUrl: `${origin}`,
    title: "sidenav.pages.website.url",
    icon: GlobalOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Logout",
    path: `${APP_PREFIX_PATH}/logout`,
    title: "sidenav.users.logout",
    icon: LogoutOutlined,
    breadcrumb: true,
    submenu: [],
  },
];

const dashBoardNavTree = [
  {
    key: "dashboard-root",
    title: "sidenav.dashboard",
    breadcrumb: false,
    submenu: [
      {
        key: "dashboard-home",
        path: `${APP_PREFIX_PATH}/dashboard/default`,
        title: "home",
        icon: HomeOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "gallery",
    title: "sidenav.gallery",
    icon: PictureOutlined,
    breadcrumb: true,
    path: `${APP_PREFIX_PATH}/gallery/list`,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree, ...extraNavTree];

export default navigationConfig;
