import api from "api";
import API from "../../constants/ApiConstant";
import qs from "query-string";

export function listUser(query) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.USERS}/admin/all-users?${qs.stringify(query)}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function hideUser(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .patch(`${API.USERS}/admin/hide-user/${id}`, payload)
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getUserById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.USERS}/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });
}

export function approveUser(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .patch(`${API.USERS}/admin/approve/${id}`, payload)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });
}

export function createGalleryUser(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`${API.USERS}/admin/add/user`, payload)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });
}

export function editUserInfo(id,payload){
  return new Promise((resolve, reject) => {
    api
      .patch(`${API.USERS}/admin/update-user/${id}`, payload)
      .then((res) => resolve(res.data))
      .catch((err) => {
        console.log(err)
        reject(err?.response?.data);
      });
  });
}