/* eslint-disable import/no-anonymous-default-export */
import ACTIONS from './Action';

export default (state, action) => {
	switch (action.type) {
		case ACTIONS.LIST_NFT:
			return {
				...state,
				list: action.data.data,
				pagination: {
					limit: parseInt(action.data.limit),
					start: parseInt(action.data.start),
					total: parseInt(action.data.total),
					currentPage: parseInt(action.data.page),
					totalPages: Math.ceil(action.data.total / action.data.limit)
				}
			};

		case ACTIONS.FETCH_PRICE:
			const res = action.data;
			return {
				...state,
				price: res
			};

		default:
			return state;
	}
};
