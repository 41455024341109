import React, { createContext, useReducer, useCallback } from "react";
import appReduce from "./Reducer";
import * as Service from "./Service";
import Action from "./Action";

const initialState = {
  list: [],
  pagination: { limit: 10, start: 0, total: 0, currentPage: 1, totalPages: 0 },
};

export const ArtistContext = createContext(initialState);
export const ArtistContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReduce, initialState);

  const listArtist = useCallback(async (query) => {
    const res = await Service.listArtist(query);
    dispatch({ type: Action.LIST_ARTIST, data: res });
    return res;
  }, []);

  const hideArtist = (id, payload) => {
    return Service.hideArtist(id, payload);
  };

  const getById = (id) => {
    return Service.getById(id);
  };

  const featureArtist = useCallback((id, payload) => {
    return Service.featureArtist(id, payload);
  }, []);

  return (
    <ArtistContext.Provider
      value={{
        is_app_ready: state.is_app_ready,
        pagination: state.pagination,
        dispatch,
        listArtist,
        hideArtist,
        featureArtist,
        getById,
      }}
    >
      {children}
    </ArtistContext.Provider>
  );
};
