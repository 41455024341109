import api from "api";
import API from "constants/ApiConstant";
import qs from "query-string";

export const createCollection = (payload) => {
  return new Promise((resolve, reject) => {
    api
      .post(API.BUNDLE, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const updateCollection = (id, payload) => {
  return new Promise((resolve, reject) => {
    api
      .put(`${API.BUNDLE}/${id}`, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export function listCollections(query = {}) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.COLLECTION}?${qs.stringify(query)}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
}

export function getById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.COLLECTION}/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
}

export function featureCollection(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .put(`${API.COLLECTION}/admin/feature-collection/${id}`, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
}

export function getFeaturedCollections(query = {}) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.COLLECTION}/admin/featured`, query)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
}

export function hideCollections(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .put(`${API.COLLECTION}/admin/hide-collection/${id}`, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
}

export function getByGallery(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.COLLECTION}/galleries/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
}
