import api from "api";
import API from "../../constants/ApiConstant";

export function listGallery(params) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.GALLERY}`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getGalleryById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.GALLERY}/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function hideGallery(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .put(`${API.GALLERY}/admin/hide-gallery/${id}`, payload)
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
}
export function featureGallery(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .put(`${API.GALLERY}/admin/feature-gallery/${id}`, payload)
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
}

export function getFeaturedGalleries(params) {
  return new Promise((resolve, reject) => {
    api
      .get(`${API.GALLERY}/admin/featured`, { params })
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
}

export function editGalleryInfo(id,payload){
  try{return new Promise((resolve, reject) => {
    api
      .put(`${API.GALLERY}/${id}`,payload)
      .then((res) => resolve(res.data))
      .catch((err) => {
        
        reject(err?.response?.data);
      })
    })}catch(err){
        console.log(err)
      }

  ;}