import React, { createContext, useReducer, useCallback } from "react";
import appReduce from "./Reducer";
import * as Service from "./Service";
import Action from "./Action";

const initialState = {
  list: [],
  pagination: { limit: 10, start: 0, total: 0, currentPage: 1, totalPages: 0 },
};

export const NftContext = createContext(initialState);
export const NftContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReduce, initialState);

  const listNft = useCallback(async (query) => {
    const res = await Service.listNft(query);
    dispatch({ type: Action.LIST_NFT, data: res });
    return res;
  }, []);

  const getNftById = useCallback((id) => {
    return Service.getNftById(id);
  }, []);

  const listCategories = useCallback((query) => {
    return Service.listCategories(query);
  }, []);

  const hideNft = (id, payload) => {
    return Service.hideNft(id, payload);
  };

  const featureNft = (id, payload) => {
    return Service.featureNFT(id, payload);
  };

  const getFeaturedNfts = useCallback((query) => {
    return Service.getFeaturedNFTs(query);
  }, []);

  return (
    <NftContext.Provider
      value={{
        is_app_ready: state.is_app_ready,
        pagination: state.pagination,
        dispatch,
        listNft,
        getNftById,
        listCategories,
        hideNft,
        featureNft,
        getFeaturedNfts,
      }}
    >
      {children}
    </NftContext.Provider>
  );
};
